export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST",
  PRODUCTS = "PRODUCTS",
  PRODUCTS_SET_FILTER = "PRODUCTS_SET_FILTER",
  PRODUCTS_SET_SORT = "PRODUCTS_SET_SORT",
  PRODUCTS_SET_PAGE = "PRODUCTS_SET_PAGE",
  PRODUCTS_SET_PRICE_FILTER = "PRODUCTS_SET_PRICE_FILTER",
  PRODUCTS_SET_ALL_FILTERS = "PRODUCTS_SET_ALL_FILTERS",
  PRODUCTS_RESET_FILTERS = "PRODUCTS_RESET_FILTERS",
  REVIEWS_REQUEST = "REVIEWS_REQUEST",
  REVIEWS = "REVIEWS",
  REVIEWS_CLOSE = "REVIEWS_CLOSE";
