export function formatPrice(price, unit = " ₽") {
  return (
    String(Math.round(price) || 0).replace(/(.)(?=(\d{3})+$)/g, "$1 ") +
    (unit || "")
  );
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function capitalizeFirstLetter(str) {
  str = String(str);

  return str.charAt(0).toUpperCase() + str.slice(1);
}
