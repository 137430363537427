import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

export default function HoverAnimation({ className, children }) {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const onMouseEnter = useCallback(() => setHovered(true), []);
  const onMouseLeave = useCallback(() => setHovered(false), []);

  return (
    <CSSTransition
      in={hovered}
      timeout={400}
      classNames={{
        enter: classes.hoverTtransitionEnter,
        enterActive: classes.hoverTtransitionEnterActive,
        enterDone: classes.hoverTtransitionEnterActive,
        exit: classes.hoverTtransitionExit,
        exitActive: classes.hoverTtransitionExitActive,
      }}
    >
      <div
        className={clsx(classes.container, className)}
        {...{ onMouseEnter, onMouseLeave }}
      >
        {children}
      </div>
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    borderColor: theme.palette.common.separator,
    borderStyle: "solid",
    borderWidth: 1,
    transition: "border-color 0.3s ease-in-out",
    "&::before": {
      zIndex: -1,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      transition: "opacity 0.3s ease-in-out",
      opacity: 0,
      boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.25)",
    },
  },
  hoverTtransitionEnterActive: {
    zIndex: 1,
    borderColor: "transparent",
    "&::before": {
      opacity: 1,
    },
  },
  hoverTtransitionExitActive: {
    zIndex: 1,
  },
}));
