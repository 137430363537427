import React from "react";
import { Button, withStyles } from "@material-ui/core";

const OutlinedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 300,
    textTransform: "none",
    height: 32,
    padding: "5px 10px",
    marginRight: theme.spacing(1),
  },
}))(Button);

export default React.memo(OutlinedButton);
