import React, { useCallback } from "react";
import { Accordion, PriceFilter, OptionFilter } from "components";
import { useSelector, useDispatch } from "react-redux";
import actions from "actions";

export default function SideBar() {
  const dispatch = useDispatch();
  const {
    filters,
    meta: { price, promo, color, memory },
  } = useSelector((st) => st.products);
  const onChange = (filter) => (e) =>
    dispatch(
      actions.productsSetFilter(filter, e.target.name, e.target.checked)
    );
  const onChangePrice = useCallback(
    ([min, max]) => dispatch(actions.productsSetPriceFilter(min, max)),
    [dispatch]
  );

  return (
    <>
      <Accordion defaultExpanded title="Цена">
        <PriceFilter
          start={filters.price.min}
          end={filters.price.max}
          onChange={onChangePrice}
          {...price}
        />
      </Accordion>
      <Accordion title="Акция">
        <OptionFilter
          options={promo}
          selected={filters.promo || {}}
          onChange={onChange("promo")}
        />
      </Accordion>
      <Accordion title="Цвет">
        <OptionFilter
          options={color}
          selected={filters.color || {}}
          onChange={onChange("color")}
        />
      </Accordion>
      <Accordion title="Объем оперативной памяти">
        <OptionFilter
          options={memory}
          selected={filters.memory || {}}
          onChange={onChange("memory")}
        />
      </Accordion>
    </>
  );
}
