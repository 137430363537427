import React from "react";
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import { ReactComponent as Chevron } from "svg/chevron.svg";

const StyledAccordion = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.common.separator}`,
    boxShadow: "none",
    margin: "0 !important",
    border: "none",
    "&:before": {
      display: "none",
    },
  },
}))(MUIAccordion);

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: "40px !important",
    height: 40,
    padding: 0,
  },
  expandIcon: {
    transform: "rotate(270deg)",
  },
}))(AccordionSummary);


const StyledAccordionDetails = withStyles({
  root: {
    padding: [[0, 0, 20, 0]],
    flexDirection: "column",
  },
})(AccordionDetails);

export default function Accordion({ title, defaultExpanded, children }) {
  const classes = useStyles();

  return (
    <StyledAccordion {...{ defaultExpanded }}>
      <StyledAccordionSummary
        expandIcon={
          <span className={classes.iconContainer}>
            <Chevron viewBox="0 0 14 8" />
          </span>
        }
      >
        <Typography className={classes.title}>{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}

const useStyles = makeStyles({
  // prevent glitch
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 14,
    height: 14,
  },
  title: {
    fontSize: 16,
    fontFamily: "Officina Serif",
  },
});
