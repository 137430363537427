import React, { useState, useCallback } from "react";
import { CssBaseline, makeStyles, Typography } from "@material-ui/core";
import {
  SideBar,
  Loading,
  TopBar,
  Products,
  Pagination,
  Reviews,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";

export default function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { init, isLoading, products } = useSelector((st) => st.products);
  const [productGrid, setProductGrid] = useState(false);
  const showReviews = useCallback(
    (id, rate, count) => dispatch(actions.reviewsRequest(id, rate, count)),
    [dispatch]
  );
  const resetFilters = useCallback(
    () => dispatch(actions.productsResetFilters()),
    [dispatch]
  );

  return (
    <>
      <CssBaseline />
      <div className={classes.wrapper}>
        <div className={classes.main}>
          {init ? (
            <>
              <div className={classes.sidebar}>
                <div className={classes.header}>
                  <Typography className={classes.sideBarHeader}>
                    Фильтры
                  </Typography>
                </div>
                <SideBar />
              </div>
              <div className={classes.content}>
                <div className={classes.header}>
                  <Typography className={classes.contentHeader}>
                    Смартфоны
                  </Typography>
                </div>
                <TopBar {...{ productGrid, setProductGrid }} />
                <Products
                  {...{
                    productGrid,
                    products,
                    isLoading,
                    showReviews,
                    resetFilters,
                  }}
                />
                <Pagination />
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <Reviews />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    padding: [[48, 104, 56, 112]],
    width: "100%",
    minHeight: "100%",
    backgroundColor: theme.palette.common.white,
  },
  main: {
    flex: 1,
    display: "flex",
    maxWidth: 1180,
    margin: "auto",
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    width: 288,
    marginRight: 24,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  header: {
    height: 80,
  },
  sideBarHeader: {
    fontSize: 24,
    lineHeight: "48px",
    fontFamily: "Officina Serif",
  },
  contentHeader: {
    fontSize: 50,
    lineHeight: "56px",
    fontFamily: "Officina Serif",
  },
}));
