import { getRandomInt } from "utils";
import data from "./data";
import reviews from "./reviews";

export function getProducts(filters) {
  return request(_getProducts, filters);
}

export function getReviews(id) {
  return request(_getReviews, id);
}

function _getProducts(filters) {
  const meta = {
    price: { min: 0, max: 0 },
    promo: {},
    color: {},
    memory: {},
    count: 0,
  };
  const prices = [];
  const products = [];

  for (let i = 0; i < data.length; i++) {
    const cur = data[i];

    if (cur.promoSlug && !meta.promo[cur.promoSlug]) {
      meta.promo[cur.promoSlug] = {
        value: cur.promoSlug,
        label: cur.promo,
        count: 0,
        id: cur.id,
      };
    }

    if (!meta.color[cur.colorSlug]) {
      meta.color[cur.colorSlug] = {
        value: cur.colorSlug,
        label: cur.colorName,
        color: cur.color,
        count: 0,
      };
    }

    if (!meta.memory[cur.memory]) {
      meta.memory[cur.memory] = {
        value: cur.memory,
        label: cur.memory + " Гб",
        count: 0,
      };
    }

    const skipByPromo =
      !checkIsFilterEmpty(filters.promo) && !filters.promo[cur.promoSlug];
    const skipByColor =
      !checkIsFilterEmpty(filters.color) && !filters.color[cur.colorSlug];
    const skipByMemory =
      !checkIsFilterEmpty(filters.memory) && !filters.memory[cur.memory];
    const skipByPrice =
      cur.price < filters.price.min || cur.price > filters.price.max;

    if (!skipByPromo && !skipByColor && !skipByMemory) prices.push(cur.price);
    if (cur.promoSlug && !skipByPrice && !skipByColor && !skipByMemory)
      meta.promo[cur.promoSlug].count++;
    if (!skipByPrice && !skipByPromo && !skipByMemory)
      meta.color[cur.colorSlug].count++;
    if (!skipByPrice && !skipByPromo && !skipByColor)
      meta.memory[cur.memory].count++;

    if (skipByPromo || skipByColor || skipByMemory || skipByPrice) continue;

    products.push(data[i]);
  }

  meta.promo = Object.values(meta.promo);
  meta.color = Object.values(meta.color);
  meta.memory = Object.values(meta.memory).sort((a, b) =>
    parseInt(a.label) < parseInt(b.label) ? 1 : -1
  );

  if (prices.length) {
    meta.price.min = Math.min(...prices);
    meta.price.max = Math.max(...prices);
  }

  meta.count = products.length;

  const start = ((filters.page || 1) - 1) * 6;

  const sortedProducts = products.sort((a, b) => {
    switch (filters.sort) {
      case "asc":
        return a.price - b.price;

      case "desc":
        return b.price - a.price;

      default:
        return b.rate - a.rate;
    }
  });

  return { meta, products: sortedProducts.slice(start, start + 6) };
}

function request(...args) {
  const [fn, ...rest] = args;

  return new Promise((resolve) => {
    setTimeout(() => resolve(fn(...rest)), getRandomInt(0, 1000));
  });
}

function checkIsFilterEmpty(filter) {
  return !Object.values(filter).filter(Boolean).length;
}

function _getReviews(id) {
  return reviews[id];
}
