import { createActions, handleActions } from "redux-actions";
import * as actionTypes from "actionTypes";

const defaultState = {
  products: [],
  meta: {
    price: {},
    promo: [],
    color: [],
    memory: [],
    count: 0,
  },
  filters: {
    price: {},
    promo: {},
    color: {},
    memory: {},
    page: 1,
    sort: false,
  },
  isLoading: true,
  init: false,
};

export const actions = createActions({
  [actionTypes.PRODUCTS_REQUEST]: null,
  [actionTypes.PRODUCTS]: (meta, products) => ({ meta, products }),
  [actionTypes.PRODUCTS_SET_SORT]: (sort) => sort,
  [actionTypes.PRODUCTS_SET_PAGE]: (page) => page,
  [actionTypes.PRODUCTS_SET_FILTER]: (filter, name, value) => ({
    filter,
    name,
    value,
  }),
  [actionTypes.PRODUCTS_SET_PRICE_FILTER]: (min, max) => ({ min, max }),
  [actionTypes.PRODUCTS_SET_ALL_FILTERS]: (filters) => filters,
  [actionTypes.PRODUCTS_RESET_FILTERS]: null,
});

export default handleActions(
  {
    [actionTypes.PRODUCTS_REQUEST]: (state) => ({ ...state, isLoading: true }),
    [actionTypes.PRODUCTS]: (state, { payload: { meta, products } }) => ({
      ...state,
      meta,
      products,
      isLoading: false,
      init: true,
    }),
    [actionTypes.PRODUCTS_SET_SORT]: (state, { payload: sort }) => ({
      ...state,
      filters: { ...state.filters, page: 1, sort },
    }),
    [actionTypes.PRODUCTS_SET_PAGE]: (state, { payload: page }) => ({
      ...state,
      filters: { ...state.filters, page },
    }),
    [actionTypes.PRODUCTS_SET_PRICE_FILTER]: (
      state,
      { payload: { min, max } }
    ) => ({
      ...state,
      filters: {
        ...state.filters,
        price: { min, max },
        page: 1,
        sort: false,
      },
    }),
    [actionTypes.PRODUCTS_SET_FILTER]: (
      state,
      { payload: { filter, name, value } }
    ) => ({
      ...state,
      filters: {
        ...state.filters,
        [filter]: {
          ...state.filters[filter],
          [name]: value,
        },
        page: 1,
        sort: false,
      },
    }),
    [actionTypes.PRODUCTS_SET_ALL_FILTERS]: (state, { payload: filters }) => ({
      ...state,
      filters,
    }),
    [actionTypes.PRODUCTS_RESET_FILTERS]: (state) => ({
      ...state,
      filters: defaultState.filters,
    }),
  },
  defaultState
);
