import "index.css";
import "reactotron";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import reportWebVitals from "reportWebVitals";
import store from "reduxStore";
import theme from "theme";
import App from "App";

ReactDOM.render(
  <Provider {...{ store }}>
    <ThemeProvider {...{ theme }}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
