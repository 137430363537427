import { takeLatest, put, call, delay } from "redux-saga/effects";
import * as actionTypes from "actionTypes";
import actions from "actions";
import api from "api";

export default function* reviews() {
  yield takeLatest(actionTypes.REVIEWS_REQUEST, reviewsRequest);
}

export function* reviewsRequest({ payload: { id } }) {
  yield delay(500); // just for beautiful ui/ux

  const reviews = yield call(api.web.getReviews, id);

  yield put(actions.reviews(reviews));
}
