import React, { useRef, useState, useEffect } from "react";
import { makeStyles, Grid, Typography, SvgIcon, Grow } from "@material-ui/core";
import {
  SimpleButton,
  PrimaryButton,
  Loading,
  HoverAnimation,
  Feedback,
} from "components";
import { ReactComponent as Compare } from "svg/compare.svg";
import { ReactComponent as Shipping } from "svg/ship.svg";
import { ReactComponent as Geo } from "svg/geo.svg";
import { formatPrice } from "utils";
import clsx from "clsx";

export default function Products({
  productGrid,
  products,
  isLoading,
  showReviews,
  resetFilters,
}) {
  const classes = useStyles();
  const timer = useRef();
  const [show, setShow] = useState(true);
  const [grid, setGrid] = useState(productGrid);

  useEffect(() => {
    setShow(false);

    timer.current = setTimeout(() => {
      setShow(true);
      setGrid(productGrid);
    }, 500);

    return () => clearTimeout(timer.current);
  }, [productGrid]);

  return (
    <Grid container className={classes.container}>
      {products.map((item, i) => (
        <Grow key={item.id} in={!isLoading && show} timeout={300 + i * 150}>
          {grid ? (
            <Grid item className={clsx(classes.zIndex, classes.grid)} xs={4}>
              <HoverAnimation className={classes.gridContainer}>
                <div>
                  <div className={classes.imgContainer}>
                    <img
                      className={classes.imgGrid}
                      src={item.img}
                      alt={item.name}
                    />
                  </div>
                  <Typography
                    className={clsx(
                      classes.title,
                      classes.center,
                      classes.paddingHorizontal
                    )}
                  >
                    {item.name}
                  </Typography>
                </div>
                <div>
                  <Feedback
                    className={clsx(classes.feedback, classes.justify)}
                    rate={item.rate}
                    count={item.feedbackCount}
                    onClick={
                      item.feedbackCount
                        ? () =>
                            showReviews(item.id, item.rate, item.feedbackCount)
                        : undefined
                    }
                  />
                  <Typography className={clsx(classes.price, classes.center)}>
                    {formatPrice(item.price)}
                  </Typography>
                  <div className={classes.center}>
                    <PrimaryButton
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      fullWidth
                      disableElevation
                    >
                      Купить
                    </PrimaryButton>
                  </div>
                  <div className={clsx(classes.center, classes.flex)}>
                    <Typography className={classes.fastOrder}>
                      Быстрый заказ
                    </Typography>
                  </div>
                  <div className={classes.gridShipping}>
                    {item.pickup && (
                      <SimpleButton
                        className={classes.bold}
                        startIcon={
                          <SvgIcon viewBox="0 0 6 12" component={Geo} />
                        }
                      >
                        Самовывоз
                      </SimpleButton>
                    )}
                    {item.fastDelivery && (
                      <SimpleButton
                        className={classes.bold}
                        startIcon={
                          <SvgIcon viewBox="0 0 17 10" component={Shipping} />
                        }
                      >
                        Доставка за 2 часа
                      </SimpleButton>
                    )}
                  </div>
                </div>
              </HoverAnimation>
            </Grid>
          ) : (
            <Grid container className={clsx(classes.zIndex, classes.product)}>
              <HoverAnimation className={classes.productContainer}>
                <div className={classes.imgContainer}>
                  <img className={classes.img} src={item.img} alt={item.name} />
                </div>
                <div className={classes.textContainer}>
                  <Typography className={classes.title}>{item.name}</Typography>
                  <Feedback
                    className={classes.feedback}
                    rate={item.rate}
                    count={item.feedbackCount}
                    onClick={
                      item.feedbackCount
                        ? () =>
                            showReviews(item.id, item.rate, item.feedbackCount)
                        : undefined
                    }
                  />
                  <SimpleButton
                    startIcon={
                      <SvgIcon
                        className={classes.icon}
                        viewBox="0 0 17 16"
                        component={Compare}
                      />
                    }
                  >
                    Добавить в сравнение
                  </SimpleButton>
                  <div className={classes.specs}>
                    <div className={classes.specTitle}>
                      <Typography className={classes.greyText}>
                        Объем памяти
                      </Typography>
                    </div>
                    <Typography className={classes.blackText}>
                      {item.memory} Гб
                    </Typography>
                  </div>
                  <div className={classes.specs}>
                    <div className={classes.specTitle}>
                      <Typography className={classes.greyText}>
                        Камера
                      </Typography>
                    </div>
                    <Typography className={classes.blackText}>
                      {item.camera}
                    </Typography>
                  </div>
                  <div className={classes.specs}>
                    <div className={classes.specTitle}>
                      <Typography className={classes.greyText}>
                        Экран
                      </Typography>
                    </div>
                    <Typography className={classes.blackText}>
                      {item.screen}
                    </Typography>
                  </div>
                </div>
                <div className={classes.buyContainer}>
                  <Typography className={classes.price}>
                    {formatPrice(item.price)}
                  </Typography>
                  <PrimaryButton
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disableElevation
                  >
                    Купить
                  </PrimaryButton>
                  <Typography className={classes.fastOrder}>
                    Быстрый заказ
                  </Typography>
                  <div className={classes.shipping}>
                    {item.pickup && (
                      <SimpleButton
                        className={classes.bold}
                        startIcon={
                          <SvgIcon viewBox="0 0 6 12" component={Geo} />
                        }
                      >
                        Самовывоз
                      </SimpleButton>
                    )}
                    {item.fastDelivery && (
                      <SimpleButton
                        className={classes.bold}
                        startIcon={
                          <SvgIcon viewBox="0 0 17 10" component={Shipping} />
                        }
                      >
                        Доставка за 2 часа
                      </SimpleButton>
                    )}
                  </div>
                </div>
              </HoverAnimation>
            </Grid>
          )}
        </Grow>
      ))}
      {!(isLoading || products.length) && (
        <div className={classes.empty}>
          <Typography className={classes.emptyText}>
            Увы мы ничего не нашли для вас
            <br />
            по данным парамметрам
          </Typography>
          <Typography className={classes.fastOrder} onClick={resetFilters}>
            Сбросить фильтры
          </Typography>
        </div>
      )}
      {isLoading && <Loading />}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 300,
    position: "relative",
    paddingLeft: 1,
  },
  product: {
    display: "flex",
    marginTop: -1,
  },
  productContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  specs: {
    display: "flex",
    marginTop: theme.spacing(1),
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  specTitle: {
    width: 120,
    lineHeight: 18,
  },
  blackText: {
    color: theme.palette.common.black,
    fontSize: 12,
  },
  greyText: {
    color: theme.palette.common.grey,
    fontSize: 12,
  },
  feedback: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  justify: {
    justifyContent: "center",
  },
  imgContainer: {
    height: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: 220,
    maxHeight: 250,
    maxWidth: 180,
    objectFit: "contain",
    padding: theme.spacing(2),
  },
  imgGrid: {
    width: "100%",
    maxHeight: 250,
    objectFit: "contain",
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 20,
    fontFamily: "Officina Serif",
  },
  paddingHorizontal: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  center: {
    textAlign: "center",
  },
  textContainer: {
    flex: 1,
    padding: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  notset: {
    "& path": {
      fill: theme.palette.common.separator,
    },
  },
  buyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    background: "rgba(232, 232, 232, 0.2)",
    width: 232,
    minHeight: "100%",
    padding: theme.spacing(3),
  },
  price: {
    fontSize: 30,
    fontWeight: 400,
    fontFamily: "Officina Serif",
  },
  fastOrder: {
    display: "inline-block",
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Officina Serif",
    borderBottomWidth: 1,
    borderBottomStyle: "dashed",
    lineHeight: 1,
    cursor: "pointer",
    marginBottom: theme.spacing(2),
    "&:hover": {
      color: theme.palette.primary.main,
      borderBottomColor: theme.palette.primary.main,
    },
  },
  bold: {
    "& .MuiButton-label": {
      fontWeight: 500,
    },
  },
  grid: {
    display: "flex",
    marginTop: -1,
    marginLeft: -1,
  },
  gridContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  gridShipping: {
    display: "flex",
    justifyContent: "space-around",
    background: "rgba(232, 232, 232, 0.2)",
    minHeight: 33,
  },
  button: {
    borderRadius: 150,
  },
  shipping: {
    minHeight: 66,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  empty: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 160,
  },
  emptyText: {
    fontSize: 30,
    textAlign: "center",
    fontFamily: "Officina Serif",
    lineHeight: "34px",
    marginBottom: 20,
  },
}));
