import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

export default function Input({ className, containerClassName, inputRef, ...props }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, containerClassName)}>
      <input
        className={clsx(classes.input, className)}
        {...props}
      />
      <Typography className={classes.unit}>₽</Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    flex: 1,
    height: 48,
    width: "100%",
    fontSize: 16,
    fontFamily: "Officina Serif",
    borderRadius: 4,
    border: `1px solid ${theme.palette.common.separator}`,
    padding: [[0, 37, 0, 14]],
  },
  unit: {
    position: "absolute",
    right: 14,
    pointerEvents: "none",
    fontSize: 16,
    fontFamily: "Officina Serif",
    opacity: 0.5,
  },
}));
