import { all, spawn, call } from "redux-saga/effects";

const req = require.context(".", true, /\.\/((?!index)[A-Za-z0-9]+)$/),
  sagas = req.keys().reduce((res, key) => {
    const current = req(key).default;

    return current ? [...res, current] : res;
  }, []);

export default function* root() {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      })
    )
  );
}
