import React from "react";
import { withStyles, makeStyles, Typography } from "@material-ui/core";
import { Pagination as MUIPagination } from "@material-ui/lab";
import { ReactComponent as ChevronLeft } from "svg/chevronLeft.svg";
import { ReactComponent as ChevronRight } from "svg/chevronRight.svg";
import { OutlinedButton } from "components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import actions from "actions";
import clsx from "clsx";

const StyledPagination = withStyles({
  ul: {
    "& li": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& li + li": {
      marginLeft: 16,
    },
  },
})(MUIPagination);

export default function Pagination() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, count } = useSelector(
    ({
      products: {
        filters: { page },
        meta: { count },
      },
    }) => ({ page, count }),
    shallowEqual
  );
  const onChange = (e, page) => dispatch(actions.productsSetPage(page));

  function renderItem({
    type,
    page,
    variant,
    color,
    selected,
    disabled,
    ...rest
  }) {
    const step = type === "previous" || type === "next";
    const ellipsis = type === "start-ellipsis" || type === "end-ellipsis";

    if (ellipsis)
      return <Typography className={classes.ellipsis}>...</Typography>;

    return (
      <OutlinedButton
        className={clsx(
          classes.button,
          step && classes.step,
          selected && classes.active,
          disabled && classes.disabled
        )}
        variant="outlined"
        {...{ disabled }}
        {...rest}
      >
        {getContent(type, page)}
      </OutlinedButton>
    );
  }

  const resCount = Math.ceil(count / 6);

  if (resCount < 2) return null;

  return (
    <StyledPagination
      className={classes.container}
      siblingCount={0}
      count={resCount}
      page={Math.min(Math.max(1, page), resCount)}
      {...{ renderItem, onChange }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    alignSelf: "center",
    marginTop: 40,
  },
  button: {
    fontFamily: "Officina Serif",
    height: 48,
    minWidth: 48,
    fontSize: 20,
    margin: 0,
  },
  active: {
    borderColor: theme.palette.primary.main,
    pointerEvents: "none",
  },
  step: {
    backgroundColor: theme.palette.common.separator,
  },
  disabled: {
    opacity: 0.3,
  },
  ellipsis: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 5,
    pointerEvents: "none",
  },
}));

function getContent(type, page) {
  switch (type) {
    case "previous":
      return <ChevronLeft viewBox="0 0 10 18" />;

    case "next":
      return <ChevronRight viewBox="0 0 11 18" />;

    case "start-ellipsis":
    case "end-ellipsis":
      return "...";

    default:
      return page;
  }
}
