import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Slider, Input } from "components";
import { formatPrice } from "utils";
import clsx from "clsx";

const normalize = (val, min, max) =>
  Math.max(Math.min(parseInt(val) || 0, max), min);
const normalizeByRange = (val, min, max) =>
  val < min ? min : val > max ? max : val;
const normalizeValue = (val, min, max) => {
  const v1 = normalizeByRange(val[0], min, max);
  const v2 = normalizeByRange(val[1], min, max);

  if (v1 === val[0] && v2 === val[1]) return val;

  return [v1, v2];
};

export default function PriceFilter({
  start,
  end,
  min = 1,
  max = 1000,
  onChange,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(start && end ? [start, end] : [min, max]);

  function handleBlur(v1, v2) {
    v1 = normalize(v1, min, max);
    v2 = normalize(v2, min, max);

    const newValue = [Math.min(v1, v2), Math.max(v1, v2)];

    setValue(newValue);
    onChange(newValue);
  }

  function handelChange(v1, v2) {
    const newValue = [v1, v2];

    setValue(newValue);
    onChange(newValue);
  }

  useEffect(
    () =>
      setValue((cur) => {
        if (start && end && (cur[0] !== start || cur[1] !== end))
          return [start, end];

        return cur;
      }),
    [start, end]
  );

  useEffect(
    () =>
      setValue((cur) => {
        if (cur[0] < min || cur[1] > max) return [min, max];

        return cur;
      }),
    [min, max]
  );

  return (
    <>
      <Slider
        onChange={(e, newValue) => setValue(newValue)}
        onChangeCommitted={(e, newValue) => onChange(newValue)}
        value={normalizeValue(value, min, max)} // prevent bug
        {...{ min, max }}
      />
      <div className={classes.row}>
        <Typography className={classes.label}>
          {formatPrice(min, false)}
        </Typography>
        <Typography className={classes.label}>
          {formatPrice(max, false)}
        </Typography>
      </div>
      <div className={clsx(classes.row, classes.inputs)}>
        <Input
          containerClassName={classes.input}
          value={value[0] || ""}
          onChange={(e) =>
            handelChange(parseInt(e.target.value) || 0, value[1])
          }
          onBlur={(e) => handleBlur(e.target.value, value[1])}
        />
        <Input
          value={value[1] || ""}
          onChange={(e) =>
            handelChange(value[0], parseInt(e.target.value) || 0)
          }
          onBlur={(e) => handleBlur(value[0], e.target.value)}
        />
      </div>
    </>
  );
}

const useStyles = makeStyles({
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "Officina Serif",
  },
  inputs: {
    marginTop: 18,
  },
  input: {
    marginRight: 16,
  },
});
