import { Slider, withStyles } from "@material-ui/core";

export default withStyles((theme) => ({
  root: {
    width: "calc(100% - 18px)",
    height: 8,
    padding: [[7, 0]],
    margin: [[0, 9]],
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundColor: theme.palette.common.separator,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.common.white,
    // backgroundColor: "transparent",
    border: `1px solid ${theme.palette.common.grey}`,
    marginLeft: -9,
    "&:focus, &:hover, &:active": {
      boxShadow: "inherit",
    },
  },
}))(Slider);
