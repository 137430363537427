import { put } from "redux-saga/effects";
import actions from "actions";
const names = ["promo", "color", "memory"];

export default function* init() {
  const params = new URLSearchParams(window.location.search);
  const filters = { price: {}, promo: {}, color: {}, memory: {} };

  for (let i = 0; i < names.length; i++) {
    const name = names[i];
    const param = params.get(name);

    if (!param) continue;

    const values = param.split(",");

    for (let n = 0; n < values.length; n++) {
      const value = values[n];

      filters[name][value] = true;
    }
  }

  const price = params.get("price");

  if (price) {
    const prices = price.split(":");

    filters.price = {
      min: parseInt(prices[0]) || 0,
      max: parseInt(prices[1]) || 999999,
    };
  }

  filters.page = parseInt(params.get("page")) || 1;
  filters.sort = params.get("sort") || false;

  yield put(actions.productsSetAllFilters(filters));
}
