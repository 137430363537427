import { createActions, handleActions } from "redux-actions";
import * as actionTypes from "actionTypes";

const defaultState = {
  reviews: [],
  rate: 0,
  count: 0,
  isLoading: false,
  isModalOpened: false,
};

export const actions = createActions({
  [actionTypes.REVIEWS_REQUEST]: (id, rate, count) => ({ id, rate, count }),
  [actionTypes.REVIEWS]: (reviews) => reviews,
  [actionTypes.REVIEWS_CLOSE]: null,
});

export default handleActions(
  {
    [actionTypes.REVIEWS_REQUEST]: (state, { payload: { rate, count } }) => ({
      ...state,
      rate,
      count,
      isLoading: true,
      isModalOpened: true,
    }),
    [actionTypes.REVIEWS]: (state, { payload: reviews }) => ({
      ...state,
      reviews,
      isLoading: false,
    }),
    [actionTypes.REVIEWS_CLOSE]: (state) => ({
      ...state,
      isModalOpened: false,
    }),
  },
  defaultState
);
