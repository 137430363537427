import React from "react";
import { Button, withStyles } from "@material-ui/core";

const SimpleButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    fontSize: 12,
    fontWeight: 400,
    textTransform: "none",
  },
}))(Button);

export default React.memo(SimpleButton);
