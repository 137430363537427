import React from "react";
import { makeStyles, Typography, SvgIcon } from "@material-ui/core";
import { OutlinedButton } from "components";
import { ReactComponent as Asc } from "svg/asc.svg";
import { ReactComponent as Desc } from "svg/desc.svg";
import { ReactComponent as GridView } from "svg/gridView.svg";
import { ReactComponent as ListView } from "svg/listView.svg";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import clsx from "clsx";

export default function TopBar({ productGrid, setProductGrid }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sort = useSelector((st) => st.products.filters.sort);
  const setSort = (sort) => dispatch(actions.productsSetSort(sort));
  const onClickByPrice = () => setSort(sort === "asc" ? "desc" : "asc");

  return (
    <div className={classes.topBar}>
      <div className={classes.sortContainer}>
        <Typography className={classes.sortBy}>Сортировать по</Typography>
        <OutlinedButton
          className={clsx(!sort && classes.active)}
          variant="outlined"
          onClick={() => setSort(false)}
        >
          Популярности
        </OutlinedButton>
        <OutlinedButton
          className={clsx(
            (sort === "asc" || sort === "desc") && classes.active
          )}
          variant="outlined"
          startIcon={
            <SvgIcon
              viewBox="0 0 16 14"
              component={sort === "desc" ? Desc : Asc}
            />
          }
          onClick={onClickByPrice}
        >
          Цене
        </OutlinedButton>
      </div>
      <div className={classes.viewContainer}>
        <OutlinedButton
          className={clsx(classes.iconBtn, !productGrid && classes.active)}
          variant="outlined"
          onClick={() => setProductGrid(false)}
        >
          <SvgIcon
            className={classes.icon}
            viewBox="0 0 16 16"
            component={ListView}
          />
        </OutlinedButton>
        <OutlinedButton
          className={clsx(classes.iconBtn, productGrid && classes.active)}
          style={{ marginRight: 0 }}
          variant="outlined"
          onClick={() => setProductGrid(true)}
        >
          <SvgIcon
            className={classes.icon}
            viewBox="0 0 16 16"
            component={GridView}
          />
        </OutlinedButton>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  sortContainer: {
    display: "flex",
    alignItems: "center",
  },
  sortBy: {
    fontSize: 14,
    fontWeight: 300,
    marginRight: theme.spacing(2),
  },
  active: {
    borderColor: "#F6C164",
  },
  iconBtn: {
    width: 32,
    marginLeft: theme.spacing(1),
    minWidth: 20,
    padding: 5,
  },
  icon: {
    fontSize: "1rem",
  },
}));
