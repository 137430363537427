import React, { useState } from "react";
import {
  makeStyles,
  withStyles,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

const StyledCheckBox = withStyles((theme) => ({
  root: {
    "& svg": {
      fill: theme.palette.common.separator,
    },
  },
  checked: {
    "& svg": {
      fill: theme.palette.primary.main,
    },
  },
}))(Checkbox);

const StyledFormControlLabel = withStyles({
  root: {
    marginRight: 0,
  },
  label: {
    fontSize: 14,
  },
})(FormControlLabel);

export default function OptionFilter({ options, selected, onChange }) {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const hasMore = !opened && options.length > 5;

  if (!opened) options = options.slice(0, 5);

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {options.map(({ value, color, label, count }) => (
            <StyledFormControlLabel
              key={value}
              control={
                <StyledCheckBox
                  size="small"
                  color="primary"
                  name={String(value)}
                  checked={!!selected[value]}
                  {...{ onChange }}
                />
              }
              disabled={!selected[value] && !count}
              label={
                <>
                  {!!color && (
                    <div
                      style={{ backgroundColor: color }}
                      className={clsx(
                        classes.color,
                        !selected[value] && !count && classes.disabled
                      )}
                    />
                  )}
                  {label}&nbsp;
                  <Typography
                    className={clsx(
                      classes.count,
                      !selected[value] && !count && classes.disabled
                    )}
                    component="span"
                  >
                    {count}
                  </Typography>
                </>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
      {hasMore && (
        <Typography className={classes.more} onClick={() => setOpened(true)}>
          Показать ещё
        </Typography>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14,
  },
  count: {
    fontSize: 12,
    color: theme.palette.common.grey,
  },
  more: {
    fontSize: 14,
    color: theme.palette.common.grey,
    cursor: "pointer",
    marginTop: 20,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  color: {
    height: 16,
    width: 32,
    borderRadius: 4,
    display: "inline-block",
    marginRight: 10,
    marginBottom: -3,
  },
  disabled: {
    opacity: 0.3,
  },
}));
