import React from "react";
import { Button, withStyles } from "@material-ui/core";

const PrimaryButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Officina Serif",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    maxWidth: 185,
  },
}))(Button);

export default React.memo(PrimaryButton);
