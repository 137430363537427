import { all, takeLatest, put, select, call, delay } from "redux-saga/effects";
import * as actionTypes from "actionTypes";
import actions from "actions";
import api from "api";

export default function* products() {
  yield all([
    takeLatest(actionTypes.PRODUCTS_REQUEST, productsRequest),
    takeLatest(
      [
        actionTypes.PRODUCTS_SET_ALL_FILTERS,
        actionTypes.PRODUCTS_SET_FILTER,
        actionTypes.PRODUCTS_SET_PRICE_FILTER,
        actionTypes.PRODUCTS_SET_PAGE,
        actionTypes.PRODUCTS_SET_SORT,
        actionTypes.PRODUCTS_RESET_FILTERS,
      ],
      updateUrlByFilters
    ),
  ]);
}

export function* productsRequest() {
  yield delay(500);

  const filters = yield select((st) => st.products.filters);

  const { meta, products } = yield call(api.web.getProducts, filters);

  yield put(actions.products(meta, products));
}

export function* updateUrlByFilters({ type }) {
  let query = "";
  if (type !== actionTypes.PRODUCTS_RESET_FILTERS) {
    const filters = yield select((st) => st.products.filters);

    Object.keys(filters).forEach((name) => {
      const filter = filters[name];

      if (name === "price") {
        if (filter.min || filter.max) {
          query += `${query ? "&" : ""}price=${filter.min || 0}:${
            filter.max || 999999
          }`;
        }
      } else if (name === "page" && filter && filter !== 1) {
        query += `${query ? "&" : ""}${name}=${filter}`;
      } else if (name === "sort" && filter) {
        query += `${query ? "&" : ""}${name}=${filter}`;
      } else {
        const value = Object.keys(filter)
          .filter((key) => filter[key])
          .join(",");

        if (value) query += `${query ? "&" : ""}${name}=${value}`;
      }
    });
  }

  const { pathname } = window.location;

  window.history.replaceState(
    null,
    null,
    query ? `${pathname}?${query}` : pathname
  );

  yield put(actions.productsRequest());
}
