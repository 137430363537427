import React from "react";
import { makeStyles, Typography, IconButton } from "@material-ui/core";
import { Feedback } from "components";
import { ReactComponent as Like } from "svg/like.svg";
import { ReactComponent as Dislike } from "svg/dislike.svg";
import clsx from "clsx";

export default function Review({ name, date, rate, likes, dislikes, text }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div className={classes.text}>{name}</div>
        <div className={clsx(classes.text, classes.date)}>{date}</div>
        <Feedback rateOnly {...{ rate }} />
      </div>
      <div className={classes.right}>
        <div className={classes.text}>{text}</div>
        <div className={classes.likes}>
          <Typography className={classes.rate}>Оцените отзыв:</Typography>
          <IconButton>
            <Like viewBox="0 0 17 17" />
          </IconButton>
          <Typography className={classes.count}>{likes}</Typography>
          <IconButton>
            <Dislike viewBox="0 0 17 17" />
          </IconButton>
          <Typography className={classes.count}>{dislikes}</Typography>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingTop: 16,
    paddingBottom: 24,
    "& + &": {
      borderTop: `1px solid ${theme.palette.common.separator}`,
    },
  },
  left: {
    width: 312,
  },
  right: {
    flex: 1,
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "24px",
  },
  date: {
    color: theme.palette.common.grey,
    marginTop: 2,
    marginBottom: 12,
  },
  likes: {
    display: "flex",
    alignItems: "center",
  },
  rate: {
    fontSize: 14,
    fontWeight: 300,
    marginRight: 12,
  },
  count: {
    fontSize: 14,
    fontWeight: 500,
    width: 20,
    textAlign: "center",
  },

  sortContainer: {
    display: "flex",
    alignItems: "center",
  },
  sortBy: {
    fontSize: 14,
    fontWeight: 300,
    marginRight: theme.spacing(2),
  },
  active: {
    borderColor: "#F6C164",
  },
  iconBtn: {
    width: 32,
    marginLeft: theme.spacing(1),
    minWidth: 20,
    padding: 5,
  },
  icon: {
    fontSize: "1rem",
  },
}));
