import React from "react";
import {
  makeStyles,
  Dialog,
  Divider,
  DialogContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Feedback, Review, Loading } from "components";
import { ReactComponent as Close } from "svg/close.svg";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";

export default function Reviews() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    reviews,
    rate,
    count,
    isLoading,
    isModalOpened: open,
  } = useSelector((st) => st.reviews);
  const onClose = () => dispatch(actions.reviewsClose());

  return (
    <Dialog fullWidth maxWidth="md" scroll="paper" {...{ open, onClose }}>
      <div className={classes.header}>
        <Typography className={classes.title}>Отзывы покупателей</Typography>
        <div className={classes.headerSection}>
          <Typography className={classes.rate}>Общая оценка:</Typography>
          <Feedback
            className={classes.feedback}
            count={count || reviews.length}
            {...{ rate }}
          />
          <IconButton onClick={onClose}>
            <Close viewBox="0 0 18 17" />
          </IconButton>
        </div>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        {isLoading ? (
          <Loading />
        ) : (
          reviews.map((review) => <Review key={review.id} {...review} />)
        )}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    height: 80,
    minHeight: 80,
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontFamily: "Officina Serif",
    marginLeft: 40,
  },
  headerSection: {
    display: "flex",
    alignItems: "center",
    paddingRight: 12,
  },
  rate: {
    fontSize: 12,
    fontWeight: 300,
  },
  feedback: {
    marginLeft: 25,
    marginRight: 50,
  },
  content: {
    position: "relative",
    minHeight: 200,
    padding: [[0, 40, 16, 40]],
  },
}));
