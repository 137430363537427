import React from "react";
import { makeStyles, Typography, SvgIcon } from "@material-ui/core";
import { ReactComponent as Star } from "svg/star.svg";
import plural from "plural-ru";
import clsx from "clsx";

const arr = [...new Array(5)];

export default function Feedback({
  className,
  rate,
  count,
  rateOnly = false,
  onClick,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <div>
        {arr.map((_, i) => (
          <SvgIcon
            key={i}
            className={clsx(classes.icon, i >= rate && classes.notset)}
            viewBox="0 0 23 22"
            component={Star}
          />
        ))}
      </div>
      {!rateOnly && (
        <Typography
          className={clsx(
            classes.reviews,
            !!(count && onClick) && classes.active
          )}
          {...{ onClick }}
        >
          {count} {plural(count, "отзыв", "отзыва", "отзывов")}
        </Typography>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  notset: {
    "& path": {
      fill: theme.palette.common.separator,
    },
  },
  reviews: {
    color: theme.palette.common.grey,
    fontSize: 12,
    fontWeight: 300,
  },
  active: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));
