import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

export default function Loading({ className, containerClassName, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress size={72} thickness={2} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
