import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#F6C164",
    },
    secondary: {
      main: "#ff1744",
    },
    success: {
      main: "#2cc482",
    },
    error: {
      main: "#fc3250",
    },
    disabled: {
      main: "#E8E8E8",
    },
    common: {
      black: "#2E2E2E",
      grey: "#777777",
      white: "#ffffff",
      separator: "#E8E8E8",
    },
    text: {
      primary: "#2E2E2E",
    },
    divider: "#E8E8E8",
  },
});
